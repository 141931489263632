import { useSyncExternalStore, useCallback } from 'react'
import { valueOf } from '@synqly/fun'

export { useLocalStorage, useSessionStorage }

function useLocalStorage(key, initialState) {
  return useStorage('local', key, initialState)
}

function useSessionStorage(key, initialState) {
  return useStorage('session', key, initialState)
}

/**
 * @typedef {'local' | 'session'} StorageType
 * @typedef {string | number | boolean | null} JSONPrimitive
 * @typedef {{ [key: string]: JSONValue }} JSONObject
 * @typedef {JSONValue[]} JSONArray
 * @typedef {JSONPrimitive | JSONObject | JSONArray} JSONValue
 */

/**
 * Internal storage implementation, should not be used externally to this module.
 *
 * @remarks The `initialState` will be used both as the initial state
 *  returned when rendering server side, as well as the default state set
 *  to the given key in the storage, in case it hasn't already been set.
 *  This value should ideally match what's already in storage, since
 *  otherwise it may cause hydration errors.
 *
 * @param {StorageType} type selects local storage or session storage
 * @param {string} key the name of the key to use when storing values
 * @param {JSONValue | (() => JSONValue)} initialState default value to
 *  associate with the given key or a pure function that returns a default
 *  value when called
 *
 * @returns {[any, (value: JSONValue) => void, () => void]} the current value
 *  associated with the given key, a setter function, and a function to
 *  clear the key from storage
 */
function useStorage(type, key, initialState) {
  const subscribe = useCallback(
    (onStoreChange) => {
      const store = getStorage(type)

      // In case initialState is set, overwrite whatever is in the store
      if (typeof initialState !== 'undefined') {
        setItem(store, key, valueOf(initialState))
      }

      window.addEventListener('storage', handleStorageEvent)
      return () => window.removeEventListener('storage', handleStorageEvent)

      function handleStorageEvent(event) {
        if (event.storageArea === store) {
          onStoreChange()
        }
      }
    },
    [type, key, initialState],
  )

  const getSnapshot = useCallback(() => {
    const store = getStorage(type)
    return getItem(store, key)
  }, [type, key])

  const getServerSnapshot = useCallback(
    () => valueOf(initialState),
    [initialState],
  )

  const setValue = useCallback(
    (value) => {
      const store = getStorage(type)
      setItem(store, key, value)
    },
    [type, key],
  )

  const clearValue = useCallback(() => {
    const store = getStorage(type)
    removeItem(store, key)
  }, [type, key])

  const value = useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot)
  return [value, setValue, clearValue]
}

/**
 * Gets the storage object of the given type.
 *
 * @param {StorageType} type
 * @returns {Storage}
 */
function getStorage(type) {
  return window[`${type}Storage`]
}

/**
 * Gets an item from the given storage, using the given key.
 *
 * @param {Storage} store
 * @param {string} key
 *
 * @returns {JSONValue}
 */
function getItem(store, key) {
  const serializedValue = store.getItem(key)

  try {
    return JSON.parse(serializedValue)
  } catch {
    return null
  }
}

/**
 * Sets an item in the given storage, using the given key.
 *
 * @param {Storage} store
 * @param {string} key
 * @param {JSONValue} value
 */
function setItem(store, key, value) {
  const newValue = JSON.stringify(value)
  const oldValue = store.getItem(key)
  store.setItem(key, newValue)
  dispatchStorageEvent(store, {
    key,
    oldValue,
    newValue,
  })
}

/**
 * Clears any data associated with the given key in the given storage.
 *
 * @param {Storage} store
 * @param {string} key
 */
function removeItem(store, key) {
  const oldValue = store.getItem(key)
  store.removeItem(key)
  dispatchStorageEvent(store, { key, oldValue })
}

function dispatchStorageEvent(
  /** @type{Storage} */
  storageArea,
  /** @type{StorageEventInit} */
  { key, oldValue = null, newValue = null },
) {
  return window.dispatchEvent(
    new StorageEvent('storage', {
      key,
      oldValue,
      newValue,
      storageArea,
      url: window.location.toString(),
    }),
  )
}
