import { Flex, Text, Callout } from '@radix-ui/themes'
import {
  InfoCircledIcon,
  LockOpen1Icon,
  UpdateIcon,
} from '@radix-ui/react-icons'
import { SemanticColors } from '../styles/colors.js'
import { Snippet } from './snippet.jsx'
import { ConfirmationDialog } from './confirmation-dialog.jsx'
import { Link } from './link.jsx'

export function TokenReveal({ title = 'New tokens', token, ...props }) {
  const content = (
    <Flex direction="column" gap="4">
      <Text>
        When making requests to Synqly API endpoints, every request must include
        an access token. Below you will find the primary access token for your
        organization, used to authenticate requests to the Synqly management
        APIs.
      </Text>

      <Link href="https://docs.synqly.com/reference/api-authentication">
        Learn more about authentication
      </Link>

      <Flex align="center" direction="column" gap="4" my="4">
        <Snippet
          label={
            <>
              <LockOpen1Icon />
              Access token
            </>
          }
        >
          {token.access.secret}
        </Snippet>

        <Snippet
          label={
            <>
              <UpdateIcon />
              Refresh token
            </>
          }
        >
          {token.refresh.secret}
        </Snippet>
      </Flex>

      <Callout.Root color={SemanticColors.INFO} role="alert" variant="outline">
        <Callout.Icon>
          <InfoCircledIcon />
        </Callout.Icon>
        <Callout.Text>
          Copy these tokens and record them somewhere safe. You will not be able
          to recover the tokens after closing this window.
        </Callout.Text>
      </Callout.Root>
    </Flex>
  )

  return (
    <ConfirmationDialog
      {...props}
      cancel={false}
      confirm="Close"
      description={content}
      open={!!token}
      title={title}
    />
  )
}
