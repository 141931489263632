import { useRef, useState } from 'react'
import { Text, Code, Flex, IconButton, Separator } from '@radix-ui/themes'
import { CopyIcon, CheckIcon, LockClosedIcon } from '@radix-ui/react-icons'
import styles from './snippet.module.css'

export { Snippet }

function Snippet({
  label = undefined,
  disabled = false,
  children = undefined,
  size = undefined,
  maxLines = Number.MAX_SAFE_INTEGER,
  variant = 'soft',
  color = disabled ? 'gray' : 'accent',
  ...props
}) {
  /** @type {import('react').MutableRefObject<HTMLElement>} */
  const contentRef = useRef()
  const [copied, setCopied] = useState(false)
  const Icon = disabled ? LockClosedIcon : CopyIcon

  return (
    <Flex
      align="center"
      className={`${styles.snippet} ${styles[variant] ?? ''}`}
      data-accent-color={color}
      gap="2"
      justify="between"
      style={{
        '--max-lines': maxLines,
      }}
      {...props}
    >
      {!!label && (
        <Flex align="center" asChild gap="2">
          <Text className={styles.snippetLabel} size={size}>
            {label}
            <Separator orientation="vertical" />
          </Text>
        </Flex>
      )}
      <Code
        className={styles.snippetContent}
        ref={contentRef}
        size={size}
        variant="ghost"
      >
        {children}
      </Code>
      <IconButton
        aria-label="Copy to clipboard"
        className={styles.copyToClipboard}
        disabled={disabled}
        onClick={copyToClipboard}
        variant="soft"
      >
        <Icon className={styles.copyIcon} data-copied={copied} />
        <CheckIcon className={styles.checkIcon} data-copied={copied} />
      </IconButton>
    </Flex>
  )

  async function copyToClipboard(event) {
    // Stop event from bubbling upp when copy button is clicked, in case
    // this component is used in list columns or other places where
    // clicking has select style semantics.
    event.stopPropagation()

    const { textContent } = contentRef.current
    await navigator.clipboard.writeText(textContent)

    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }
}
