import { Flex, Text, Heading } from '@synqly/ui/radix/themes'

export { ErrorPage, getInitialProps }

ErrorPage.getInitialProps = getInitialProps

function ErrorPage({ statusCode, message = StandardMessage[statusCode] }) {
  return (
    <Flex align="center" grow="1" justify="center">
      <Flex align="center" direction="column" gap="4">
        <Heading size="8">{statusCode}</Heading>
        {!!message && <Text weight="medium">{message}</Text>}
      </Flex>
    </Flex>
  )
}

async function getInitialProps({ res, err }) {
  let body

  try {
    body = (await res.json()) ?? err
  } catch {
    // Response body not strictly necessary, so ignoring parse errors
  }

  const statusCode = res?.statusCode ?? err?.statusCode ?? 404
  return {
    statusCode,
    message: body?.message ?? err?.message ?? StandardMessage[statusCode],
  }
}

const StandardMessage = {
  404: 'Not Found',
}
