import { AlertDialog, Button, Flex } from '@radix-ui/themes'
import styles from './dialog.module.css'

export { ConfirmationDialog }

/**
 * @typedef {import('react').ReactNode} ReactNode
 *
 * @typedef {import('react').MouseEventHandler} MouseEventHandler
 *
 * @typedef {import('@/styles/colors.js').ThemeScales} ThemeScales
 */

/**
 * @param {object} props
 * @param {ReactNode} props.title
 * @param {ReactNode} props.description
 * @param {ThemeScales} [props.color]
 * @param {ReactNode} [props.children]
 * @param {ReactNode} [props.cancel]
 * @param {ReactNode} [props.cancel]
 * @param {ReactNode} [props.confirm]
 * @param {MouseEventHandler} [props.onCancel]
 * @param {MouseEventHandler} [props.onConfirm]
 * @returns {JSX.Element}
 */
function ConfirmationDialog({
  title,
  description,
  color,
  children,
  cancel = 'Cancel',
  confirm = 'Confirm',
  onCancel = () => {},
  onConfirm = () => {},
  ...props
}) {
  return (
    <AlertDialog.Root {...props}>
      {!!children && <AlertDialog.Trigger>{children}</AlertDialog.Trigger>}
      <AlertDialog.Content className={styles.dialogContent}>
        <AlertDialog.Title>{title}</AlertDialog.Title>
        <AlertDialog.Description as="div" size="2">
          {description}
        </AlertDialog.Description>

        <Flex gap="3" justify="end" mt="5">
          {!!cancel && (
            <AlertDialog.Cancel>
              <Button color="gray" onClick={onCancel} variant="soft">
                {cancel}
              </Button>
            </AlertDialog.Cancel>
          )}
          <AlertDialog.Action>
            <Button color={color} onClick={onConfirm} variant="solid">
              {confirm}
            </Button>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  )
}
