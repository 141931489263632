import { useState } from 'react'
import { Flex, Text, TextField } from '@radix-ui/themes'
import * as Form from './form'

export { BasicAuth }

/** @typedef {import('react').ReactNode} ReactNode */

/**
 * @param {object} options
 * @param {ReactNode} options.children
 * @param {[ReactNode]} [options.inputs]
 * @param {(inputs: object) => Promise<Boolean>} options.onAuthenticate
 * @param {Boolean} [options.initialAuthenticated]
 */
function BasicAuth({
  children,
  inputs,
  onAuthenticate,
  initialAuthenticated = false,
}) {
  const [authenticated, setAuthenticated] = useState(!!initialAuthenticated)

  if (authenticated) {
    return children
  }

  return (
    <Form.Root
      method="post"
      onSubmit={async (event) => {
        event.preventDefault()
        const inputs = Object.fromEntries(new FormData(event.currentTarget))
        const authenticated = await onAuthenticate(inputs)
        setAuthenticated(authenticated)
      }}
    >
      <Flex direction="column" gap="3">
        {inputs ?? (
          <Form.Field name="password">
            <Flex align="center" justify="between">
              <Form.Label>
                <Text>Please enter the magic word</Text>
              </Form.Label>
            </Flex>
            <Form.Control asChild>
              <TextField.Root
                autoComplete="off"
                placeholder="password"
                required
                type="password"
              />
            </Form.Control>
          </Form.Field>
        )}
        <Form.Submit>Submit</Form.Submit>
      </Flex>
    </Form.Root>
  )
}
